import React, { Fragment } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { Loader } from '@axeedge/go-teacher-components';

import BackLink from '../../components/BackLink';
import BookHolder from './components/BookHolder';
import BookQuery from './components/BookQuery';
import { GET_BOOK_TYPE_QUERY } from '../Pack/services/graphql';

const newBookMulti = {
    name: '',
    description: '',
    foreword: '',
    resources: '',
    chapters: [
        {
            orderid: 0,
            title: 'Chapter 1',
            content: '',
            guidance: ''
        },
        {
            orderid: 1,
            title: 'Chapter 2',
            guidance: ''
        }
    ]
}

const newBookSingle = {
    name: '',
    description: '',
    foreword: '',
    resources: '',
    chapters: [
        {
            orderid: 0,
            title: 'My Entry',
            content: '',
            guidance: ''
        }
    ]
}

const Book = ({ bookId, bookTemplateCategoryId }) => {
    const backLink = `/pack/${bookTemplateCategoryId}`;
    const { data, error, loading } = useQuery(GET_BOOK_TYPE_QUERY, {
        variables: {
            bookTemplateCategoryId
        }
    });

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <Loader />
    }

    if (data && data.bookTemplateCategory) {
        const newBook = data.bookTemplateCategory.isSingleChapter ? newBookSingle : newBookMulti;
        return (
            <Fragment>
                <BackLink to={backLink} text='Pack home' />
                {
                    bookId === "new" ?
                    <BookHolder book={{ ...newBook, bookTemplateCategoryId, isSingleChapter: data.bookTemplateCategory.isSingleChapter }} isNew={true} backLink={backLink} /> :
                    <BookQuery bookTemplateCategoryId={bookTemplateCategoryId} bookId={bookId} backLink={backLink} />
                }
            </Fragment>
        )
    }

    return null;
}

export default Book;