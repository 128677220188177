import React, { useCallback, useEffect, useRef, useState, Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { navigate } from '@reach/router';
import { useMutation } from '@apollo/react-hooks';
import * as Yup from 'yup';
import EditorJs from 'react-editor-js';

import { Button, FormErrors } from '@axeedge/go-teacher-components';
import { 
    ADD_CHAPTER_TO_BOOK_MUTATION,
    EDIT_CHAPTER_MUTATION,
    GET_BOOK_QUERY, 
    ADD_BOOK_TEMPLATE_MUTATION
} from '../services/graphql';
import { GET_BOOK_TYPE_QUERY } from '../../Pack/services/graphql';

const BookChapter = ({ backLink, book, chapter }) => {
    const [formErrors, setFormErrors] = useState([]);
    const [firstChapter, setFirstChapter] = useState(null);
    const [content] = useState(() => {
        try {
            JSON.parse(chapter.content);
        } catch (e) {
            return {
                blocks: [
                    {
                        type: 'paragraph',
                        data: {
                            text: chapter.content
                        }
                    }
                ]
            }
        }
        return JSON.parse(chapter.content)
    });
    const editorJsRef = useRef(null);

    const schema = (chapter.orderid === 0 && !book.isSingleChapter) ? Yup.object().shape({
        content: Yup.string().required('Please enter chapter content')
    }) : null;

    const { register, handleSubmit, errors, reset, setValue } = useForm({
        validationSchema: schema,
        mode: 'onBlur'
    });

    const [addBook, { loading: addingBook }] = useMutation(ADD_BOOK_TEMPLATE_MUTATION, {
        variables: {
            ...book
        },
        refetchQueries: [{ 
            query: GET_BOOK_TYPE_QUERY,
            variables: {
                bookTemplateCategoryId: book.bookTemplateCategoryId
            }
        }],
        update: (_, { data }) => {
            if (data.addBookTemplate.errors && data.addBookTemplate.errors.length !== 0) {
                setFormErrors(data.addBookTemplate.errors);
                return;
            }
            if (data.addBookTemplate.bookTemplate.id) {
                newBookSaveChapter(data.addBookTemplate.bookTemplate);
            }
        }
    });

    const [addNewBookChapter, { loading: addingChapter}] = useMutation(ADD_CHAPTER_TO_BOOK_MUTATION, {
        update: (_, { data }) => {
            if (data.addBookTemplateChapter.errors && data.addBookTemplateChapter.errors.length !== 0) {
                setFormErrors(data.addBookTemplateChapter.errors);
                return;
            }
            if (data.addBookTemplateChapter.chapter.id) {
                if (data.addBookTemplateChapter.chapter.bookTemplate.chapters.length === 1 && !book.isSingleChapter) {
                    newBookSaveChapter(data.addBookTemplateChapter.chapter.bookTemplate);
                } else {
                    navigate(`/book/${book.bookTemplateCategoryId}/${data.addBookTemplateChapter.chapter.bookTemplate.id}`);
                }
            }
        }
    });

    const onEditorChange = useCallback(async () => {
        const editorData = await editorJsRef.current.save();
        setValue('content', JSON.stringify(editorData));
    }, [editorJsRef, setValue])

    const [editChapter, { loading: savingEdit }] = useMutation(EDIT_CHAPTER_MUTATION, {
        update: (_, { data }) => {
            if (data.editBookTemplateChapter.errors && data.editBookTemplateChapter.errors.length) {
                alert(data.editBookTemplateChapter.errors[0]);
            }
        },
        refetchQueries: [{ query: GET_BOOK_QUERY, variables: { bookTemplateId: book.id } }],
        awaitRefetchQueries: true
    })

    useEffect(() => {
        reset();
    }, [chapter, reset])

    const newBookSaveChapter = newBook => {
        if (newBook.chapters && newBook.chapters.length === 1) {
            addNewBookChapter({
                variables: {
                    bookTemplateId: newBook.id,
                    title: 'Chapter 2',
                    description: '',
                    content: '',
                    guidance: '',
                    orderid: 1
                }
            })
        } else {
            addNewBookChapter({
                variables: {
                    bookTemplateId: newBook.id,
                    title: firstChapter.title,
                    description: '',
                    content: firstChapter.content,
                    guidance: firstChapter.guidance,
                    orderid: 0
                }
            })
        }
    }

    const saveNewBook = firstChapter => {
        setFirstChapter(firstChapter);
        addBook();
    }

    const onSubmit = values => {
        const { content, guidance, title } = values;
        if (!book.id) {
            // we're saving the first chapter to a new book
            saveNewBook({
                content,
                guidance, 
                orderid: chapter.orderid, 
                title
            });
        } else {
            // update chapter
            editChapter({
                variables: {
                    bookTemplateChapterId: chapter.id,
                    bookTemplateId: book.id,
                    title,
                    description: chapter.description,
                    content,
                    guidance,
                    orderid: chapter.orderid
                }
            })
        }
    }

    return (
        <Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='basic-form__group'>
                    <input
                        name='title'
                        defaultValue={chapter.title}
                        placeholder='Chapter Title (optional)'
                        className='basic-form__text-box'
                        ref={register}
                        type='text'
                    />
                </div>
                {
                    chapter.orderid === 0 && !book.isSingleChapter ? 
                    (
                        <Fragment>
                            <div className='basic-form__group'>
                                <div className='basic-form__editor-holder'>
                                    <EditorJs
                                        data={content}
                                        placeholder='Initial chapter content (required)'
                                        instanceRef={instance => (editorJsRef.current = instance)}
                                        enableReInitialize={false}
                                        minHeight='0'
                                        onChange={onEditorChange}
                                        logLevel='WARN'
                                    />
                                </div>
                                
                                <input type='hidden' name='content' ref={register} defaultValue={chapter.content} />
                            </div>
                            {errors.content && <p className='basic-form__hint'>{errors.content.message}</p>}
                        </Fragment>
                    ) : (
                        <div className='basic-form__group'>
                            <textarea
                                name='guidance'
                                defaultValue={chapter.guidance}
                                placeholder='Chapter guidance for students'
                                className='basic-form__text-box'
                                ref={register}
                            />
                        </div>
                    )
                }
                {formErrors.length !== 0 && <FormErrors errors={formErrors} />}
                <div className='u-display-flex u-align-center'>
                    <Button type='submit' className='u-m-right-1'>{addingBook || addingChapter || savingEdit ? 'Saving...' : 'Save Chapter'}</Button>
                    <Button type='button' outline onClick={() => navigate(backLink)}>Cancel</Button>
                </div>
            </form>
        </Fragment>
    );
}

export default BookChapter;