import React, { Fragment, useCallback, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { navigate } from '@reach/router';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import * as Yup from 'yup';
import EditorJs from 'react-editor-js';
import axios from 'axios';

import { Button } from '@axeedge/go-teacher-components';

import { EDIT_BOOK_MUTATION, GET_BOOK_QUERY, GET_S3_UPLOAD_QUERY } from '../services/graphql';
import { CheckCircle } from 'react-feather';

const BookSettings = ({ backLink, book, onSaveNewBook }) => {
    const [coverUrl, setCoverUrl] = useState(book.coverUrl);
    const [previewUrl, setPreviewUrl] = useState(book.previewUrl);
    const [resourcesUrl, setResourcesUrl] = useState(book.resourcesUrl);

    const [forewordValue, setForewordValue] = useState();

    const client = useApolloClient();

    const [editedForeword] = useState(() => {
        try {
            JSON.parse(book.foreword);
        } catch (e) {
            return {
                blocks: [
                    {
                        type: 'paragraph',
                        data: {
                            text: book.foreword
                        }
                    }
                ]
            }
        }
        return JSON.parse(book.foreword)
    });
    const editorJsRef = useRef(null);

    const [editBook, { loading: savingBook }] = useMutation(EDIT_BOOK_MUTATION, {
        update: (_, { data }) => {
            if (data.editBookTemplate.errors && data.editBookTemplate.errors.length) {
                alert(data.editBookTemplate.errors[0]);
            }
        },
        refetchQueries: [{ query: GET_BOOK_QUERY, variables: { bookTemplateId: book.id } }],
        awaitRefetchQueries: true
    });

    const schema = Yup.object().shape({
        name: Yup.string().required('Please enter number a pack name'),
        description: Yup.string().required('Please enter a pack description'),
        coverTemplateName: Yup.string().required('Please enter cover template name'),
        foreword: Yup.string().required('Please enter a default book foreword')
    });

    const { register, handleSubmit, errors, setValue } = useForm({
        validationSchema: schema,
        mode: 'onBlur'
    });

    const onEditorChange = useCallback(async () => {
        const editorData = await editorJsRef.current.save();
        setValue('foreword', JSON.stringify(editorData));
        setForewordValue(JSON.stringify(editorData));
    }, [editorJsRef, setValue])

    const onSubmit = values => {
        const { name, description, editableTitle, coverTemplateName, foreword, resources, isBeeTemplate } = values;
        if (!book.id) {
            onSaveNewBook({
                ...book,
                name,
                coverTemplateName,
                description,
                editableTitle,
                foreword,
                resources,
                coverUrl,
                previewUrl,
                resourcesUrl
            });
        } else {
            editBook({
                variables: {
                    bookTemplateId: book.id,
                    bookTemplateCategoryId: book.bookTemplateCategoryId,
                    coverTemplateName,
                    editableTitle,
                    foreword,
                    name,
                    description,
                    isSingleChapter: book.isSingleChapter,
                    coverUrl,
                    previewUrl,
                    resourcesUrl,
                    isBeeTemplate
                }
            })
        }
    }

    const onSelectFile = (files, objectName) => {
        if (files.length) {
            const extension = files[0].name.split('.').pop().toLowerCase();
            const filename = `${book.id || ''}${objectName}-${new Date().getTime()}.${extension}`;
            client.query({
                query: GET_S3_UPLOAD_QUERY,
                variables: {
                    name: filename,
                    fileType: extension,
                    object: objectName
                }
            }).then(r => {
                const options = { headers: { 'Content-Type': '', 'x-amz-acl': 'public-read' } }
                axios.put(r.data.s3UploadUrlFor.uploadUrl, files[0], options).then(s3r => {
                    if (objectName === 'bookTemplateCover') {
                        setCoverUrl(r.data.s3UploadUrlFor.name);
                        if (book.id) {
                            editBook({
                                variables: {
                                    ...book,
                                    bookTemplateId: book.id,
                                    coverUrl: r.data.s3UploadUrlFor.name
                                }
                            })
                        }
                    }
                    if (objectName === 'bookTemplatePreview') {
                        setPreviewUrl(r.data.s3UploadUrlFor.name);
                        if (book.id) {
                            editBook({
                                variables: {
                                    ...book,
                                    bookTemplateId: book.id,
                                    previewUrl: r.data.s3UploadUrlFor.name
                                }
                            })
                        }
                    }
                    if (objectName === 'bookTemplateResources') {
                        setResourcesUrl(r.data.s3UploadUrlFor.name);
                        if (book.id) {
                            editBook({
                                variables: {
                                    ...book,
                                    bookTemplateId: book.id,
                                    resourcesUrl: r.data.s3UploadUrlFor.name
                                }
                            })
                        }
                    }
                })
            })
        }
    }

    return (
        <Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='basic-form__group basic-form__group--no-bm'>
                    <input
                        name='name'
                        placeholder='Book Name'
                        className='basic-form__text-box'
                        defaultValue={book.name}
                        ref={register}
                        type='text'
                    />
                </div>
                {errors.name && <p className='basic-form__hint'>{errors.name.message}</p>}
                <div className='basic-form__group basic-form__group--check'>
                    <input
                        name='editableTitle'
                        className='basic-form__check-box'
                        defaultChecked={book.editableTitle}
                        ref={register}
                        type='checkbox'
                    />
                    <label className='basic-form__check-label' htmlFor='titleEditable'>Teacher can edit title?</label>
                </div>
                <div className='basic-form__group basic-form__group--check'>
                    <input
                        name='isBeeTemplate'
                        className='basic-form__check-box'
                        defaultChecked={book.isBeeTemplate}
                        ref={register}
                        type='checkbox'
                    />
                    <label className='basic-form__check-label' htmlFor='isBeeTemplate'>Book is Bee Template?</label>
                </div>
                <div className='basic-form__group'>
                    <textarea
                        name='description'
                        placeholder='Book Description'
                        className='basic-form__text-box'
                        defaultValue={book.description}
                        ref={register}
                    />
                </div>
                {errors.description && <p className='basic-form__hint'>{errors.description.message}</p>}
                <div className='basic-form__group'>
                    <input
                        name='coverTemplateName'
                        placeholder='Cover Template Name'
                        className='basic-form__text-box'
                        defaultValue={book.coverTemplateName}
                        ref={register}
                        type='text'
                    />
                </div>
                {errors.coverTemplateName && <p className='basic-form__hint'>{errors.coverTemplateName.message}</p>}
                <div className='basic-form__group'>
                    <div className='basic-form__editor-holder'>
                        <EditorJs
                            data={editedForeword}
                            placeholder='Default foreword (required)'
                            instanceRef={instance => (editorJsRef.current = instance)}
                            enableReInitialize={false}
                            minHeight='0'
                            onChange={onEditorChange}
                            logLevel='WARN'
                        />
                    </div>
                    <input type='hidden' name='foreword' ref={register} defaultValue={book.foreword || forewordValue} />
                </div>
                {errors.foreword && <p className='basic-form__hint'>{errors.foreword.message}</p>}
                {
                    coverUrl ? (
                        <div className='u-m-base-3 u-display-flex u-align-center'>
                            <p className='u-m-right-3 u-display-flex u-align-center'><CheckCircle size='18' className='u-m-right-1' /> Cover uploaded</p>
                            <Button type='button' onClick={() => setCoverUrl(null)}>Change</Button>
                        </div>
                    ) :
                    (
                        <div className='basic-form__group basic-form__group--check'>
                            <label className='basic-form__label' htmlFor='resources'>Cover Image</label>
                            <input
                                name='resources'
                                className='basic-form__text-box'
                                placeholder='Cover Image'
                                onChange={e => onSelectFile(e.target.files, 'bookTemplateCover')}
                                type='file'
                                accept='image/*'
                            />
                        </div>
                    )
                }
                {
                    previewUrl ? (
                        <div className='u-m-base-3 u-display-flex u-align-center'>
                            <p className='u-m-right-3 u-display-flex u-align-center'><CheckCircle size='18' className='u-m-right-1' /> Preview uploaded</p>
                            <Button type='button' onClick={() => setPreviewUrl(null)}>Change</Button>
                        </div>
                    ) :
                    (
                        <div className='basic-form__group basic-form__group--check'>
                            <label className='basic-form__label' htmlFor='resources'>Book Preview</label>
                            <input
                                name='resources'
                                className='basic-form__text-box'
                                placeholder='Book Preview'
                                onChange={e => onSelectFile(e.target.files, 'bookTemplatePreview')}
                                type='file'
                            />
                        </div>
                    )
                }
                {
                    resourcesUrl ? (
                        <div className='u-m-base-3 u-display-flex u-align-center'>
                            <p className='u-m-right-3 u-display-flex u-align-center'><CheckCircle size='18' className='u-m-right-1' /> Resources uploaded</p>
                            <Button type='button' onClick={() => setResourcesUrl(null)}>Change</Button>
                        </div>
                    ) :
                    (
                        <div className='basic-form__group basic-form__group--check'>
                            <label className='basic-form__label' htmlFor='resources'>Teacher Resources</label>
                            <input
                                name='resources'
                                className='basic-form__text-box'
                                placeholder='Teacher resources'
                                onChange={e => onSelectFile(e.target.files, 'bookTemplateResources')}
                                type='file'
                            />
                        </div>
                    )
                }
                
                <div className='u-display-flex u-align-center'>
                    <Button disabled={!(coverUrl)} type='submit' className='u-m-right-1'>{savingBook ? 'Saving...' : 'Save Book'}</Button>
                    <Button type='button' outline onClick={() => navigate(backLink)}>Cancel</Button>
                </div>
            </form>
        </Fragment>
    );
}

export default BookSettings;
